import axios from "axios";
import { notify } from "@kyvg/vue3-notification";

export default {
	namespaced: true,

	state: () => ({
		partner: null,
		currentPartner: null,
		currentExpert: null,
		partners: [],
		jobFormats: null,
		services: null,
		experts: [],
		expert: null,
		reviews: [],
		review: null,
		centers: null
	}),

	mutations: {
		setPartner(state, data) {
			state.partner = data
		},
		setPartners(state, data) {
			state.partners = data
		},
		setServices(state, data) {
			state.services = data
		},
		setJobFormats(state, data) {
			state.jobFormats = data
		},
		setExpert(state, data) {
			state.expert = data
		},
		setExperts(state, data) {
			state.experts = data
		},
		setReview(state, data) {
			state.review = data
		},
		setReviews(state, data) {
			state.reviews = data
		},
		setCurrentPartner(state, data) {
			state.currentPartner = data;
		},
		setCurrentExpert(state, data) {
			state.currentExpert = data;
		},
		setCenters(state, data) {
			state.centers = data;
		}
	},

	actions: {
		async getPartner({ commit }, id) {
			await axios.get(`/partners/${id}`)
				.then(({ data }) => {
					commit("setPartner", data)
				})
		},
		async getCurrentPartner({ commit }) {
			await axios.get(`/partners/current`)
				.then(({ data }) => {
					commit("setCurrentPartner", data)
				})
		},
		async getCurrentExpert({ commit }) {
			await axios.get(`/partners/experts/current`)
				.then(({ data }) => {
					commit("setCurrentExpert", data)
				})
		},
		async createPartner({ commit }, data) {
			await axios.post(`/partners`, data)
				.then(({ data }) => {
					commit("setPartner", data)
					notify({
						title: "Данные сохранены",
						type: "success"
					})
				})
		},
		async getPartners({ commit }) {
			await axios.get(`/partners`)
				.then(({ data }) => {
					commit("setPartners", data)
				})
		},
		async getCenters({ commit }) {
			await axios.get(`/partners/centers`)
				.then(({ data }) => {
					commit("setCenters", data)
				})
		},
		async updatePartner({ commit }, request) {
			await axios.put(`/partners/${request.id}`, request.partner)
				.then(({ data }) => {
					commit("setPartner", data)
					notify({
						title: "Данные сохранены",
						type: "success"
					})
				})
		},
		async getServices({ commit }) {
			await axios.get(`/public/partners/services`)
				.then(({ data }) => {
					commit("setServices", data)
				})
		},
		async getJobFormats({ commit }) {
			await axios.get(`/public/partners/job_formats`)
				.then(({ data }) => {
					commit("setJobFormats", data)
				})
		},
		async getExpert({ commit }, id) {
			await axios.get(`/partners/experts/${id}`)
				.then(({ data }) => {
					commit("setExpert", data)
				})
		},
		async getExperts({ commit }) {
			await axios.get(`/partners/experts`)
				.then(({ data }) => {
					commit("setExperts", data)
				})
		},
		async createExpert({ commit }, data) {
			await axios.post(`/partners/experts`, data)
				.then(({ data }) => {
					commit("setExpert", data)
					notify({
						title: "Данные сохранены",
						type: "success"
					})
				})
		},
		async updateExpert({ commit }, request) {
			await axios.put(`/partners/experts/${request.id}`, request.expert)
				.then(({ data }) => {
					commit("setExpert", data)
					notify({
						title: "Данные сохранены",
						type: "success"
					})
				})
		},
		async getReview({ commit }, id) {
			await axios.get(`/partners/reviews/${id}`)
				.then(({ data }) => {
					commit("setReview", data)
				})
		},
		async getReviews({ commit }) {
			await axios.get(`/partners/reviews`)
				.then(({ data }) => {
					commit("setReviews", data)
				})
		},
		async createReview({ commit }, data) {
			await axios.post(`/partners/reviews`, data)
				.then(({ data }) => {
					commit("setReview", data)
					notify({
						title: "Данные сохранены",
						type: "success"
					})
				})
		},
		async updateReview({ commit }, request) {
			await axios.put(`partners/reviews/${request.id}`, request.review)
				.then(({ data }) => {
					commit("setReview", data)
					notify({
						title: "Данные сохранены",
						type: "success"
					})
				})
		},
	}
}