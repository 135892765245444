import axios from "axios";
import { notify } from "@kyvg/vue3-notification";

export default {
    namespaced: true,

    state: () => ({
        productsList: [],
        relations: [],
        entitiesList: {
            courses: [],
            tests: []
        },
        dictionaryEntities: null,
        dictionaryProducts: null,
        orders: null,
        currentOrder: null
    }),

    mutations: {
        setCurrentOrder(state, data) {
			state.currentOrder = data;
		},
        setProductsList(state, data) {
            state.productsList = data
        },
        setRelations(state, data) {
            state.relations = data
        },
        setEntitiesList(state, data) {
            state.entitiesList = data
        },
        addRelation(state, data) {
            state.relations = [...state.relations, data]
        },
        updateRelation(state, data) {
            state.relations = state.relations.map((relation) => relation.id == data.id ? data : relation)
        },
        setDictionaryEntities(state, data) {
            if (!state.dictionaryEntities) {
                const newDictionaryEntries = {}
                data.courses.forEach((el) => {
                    newDictionaryEntries[el.id] = el.title
                })
                for (let key in data.tests) {
                    newDictionaryEntries[key] = data.tests[key]
                }
                state.dictionaryEntities = newDictionaryEntries
            }

        },
        setDictionaryProducts(state, data) {
            if (!state.dictionaryProducts) {
                const newDictionaryEntries = {}
                data.forEach((el) => {
                    newDictionaryEntries[`${el.type}_${el.id}`] = el.name
                })
                state.dictionaryProducts = newDictionaryEntries
            }
        },
        setOrders(state, data) {
            state.orders = data
        },
        paidForOrder(state, data) {
            state.orders = state.orders.map((order) => order.id == data.id ? data : order)
        }
    },

    actions: {
        async getProductsList({ commit }) {
            await axios.get(`/orders/products`)
                .then(({ data }) => {
                    commit("setProductsList", data)
                    commit("setDictionaryProducts", data)
                })
        },
        async getRelations({ commit }) {
            await axios.get(`/orders/relations`)
                .then(({ data }) => {
                    commit("setRelations", data)
                })
        },
        async getEntitiesList({ commit }) {
            await axios.get(`/orders/entities`)
                .then(({ data }) => {
                    commit("setEntitiesList", data)
                    commit("setDictionaryEntities", data)
                })
        },
        async createRelation({ commit }, data) {
            await axios.post(`/orders/relations`, data)
                .then(({ data }) => {
                    commit("addRelation", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        },
        async updateRelation({ commit }, data) {
            await axios.put(`/orders/relations/${data.id}`, data)
                .then(({ data }) => {
                    commit("updateRelation", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        },
        async getOrders({ commit }) {
            await axios.get(`/orders`)
                .then(({ data }) => {
                    commit("setOrders", data.data)
                })
        },
        async paidOrder({ commit }, data) {
            await axios.put(`/orders/${data.id}/paid`, data)
                .then(({ data }) => {
                    commit("paidForOrder", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        },
        // eslint-disable-next-line no-unused-vars
        async createOrder({ commit }, data) {
            await axios.post(`/orders`, data)
                .then(() => {
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                }).catch(() => {
                    notify({
                        title: "Произошла ошибка",
                        type: "error"
                })
            })
        }
    }
}