<template>
  <button v-if="isExternal" @click="backToCabinet" class="btn btn-w">
    <span> Вернуться в мой кабинет</span>
    <i class="fal fa-door-open"></i>
  </button>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
export default {
  name: "back-cabinet-btn",
  setup() {
    const store = useStore(),
      isExternal = computed(() =>
        store.state.user && store.state.user.externalEnter
          ? store.state.user.externalEnter
          : false
      );
    const backToCabinet = () => {
      let mainToken = document.cookie.match(
        new RegExp("ei-token-main=([^;]*)")
      );
      if (mainToken) {
        store.commit("setUser", {
          token: `Bearer ${mainToken[1]}`,
        });
        window.location.href = `${process.env.VUE_APP_BACK}/auth/main`;
      }
    };

    return {
      backToCabinet,
      isExternal,
    };
  },
};
</script>

<style lang="scss" scoped>
i {
  display: none;
}
@media (max-width: 768px) {
  span {
    display: none;
  }
  i {
    display: block;
  }
}
</style>