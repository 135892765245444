import axios from "axios";
import { notify } from "@kyvg/vue3-notification";

export default {
    namespaced: true,

    state: () => ({
        notificationsList: [],
        optionsLabels: null,
        optionsList: null
    }),

    mutations: {
        setNotificationsList(state, data) {
            state.notificationsList = data;
        },
        readNotification(state, data) {
            state.notificationsList = state.notificationsList.map((notif) => {
                if (notif.id == data.id) return data;
                else return notif;
            })
        },
        setOptionsLabels(state, data) {
            state.optionsLabels = data
        },
        setOptionsList(state, data) {
            state.optionsList = data
        }
    },

    actions: {
        // eslint-disable-next-line no-unused-vars
        async createNotification({ commit }, request) {
            await axios.post(`/notices`, request).then(() => {
                notify({
                    title: "Данные сохранены",
                    type: "success"
                })
            })
        },
        async getNotificationsList({ commit }) {
            await axios.get(`/notices`).then(({ data }) => {
                commit("setNotificationsList", data)
            })
        },
        readNotification({ commit }, request) {
            axios.put(`/notices/${request.id}`).then(({ data }) => {
                commit("readNotification", data)
            })
        },
        async getOptionsLabels({ commit }) {
            await axios.get(`/notices/settings/names`).then(({ data }) => {
                commit("setOptionsLabels", data)
            })
        },
        async getOptionsList({ commit }) {
            await axios.get(`/notices/settings`).then(({ data }) => {
                commit("setOptionsList", data)
            })
        },
        // eslint-disable-next-line no-unused-vars
        async saveOptionsNotification({ commit }, request) {
            await axios.post(`/notices/settings`, request).then(() => {
                notify({
                    title: "Данные сохранены",
                    type: "success"
                })
            })
        }
    }
}