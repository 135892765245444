import axios from "axios";
import {notify} from "@kyvg/vue3-notification";

export default {
    namespaced: true,

    state: () => ({
        subscription: null,
        subscriptionDurations: [],
        subscriptionSummary: [],
        permittedSubscriptions: [],
        permittedCompanies: [],
        subscriptionList: [],
        companySubscriptionList: [],
    }),
    mutations: {
        setSubscriptionList(state, data) {
            state.subscriptionList = data
        },
        setSubscription(state, data) {
            state.subscription = data
        },
        setPermittedCompanies(state, data) {
            state.permittedCompanies = data
        },
        setPermittedSubscriptions(state, data) {
            state.permittedSubscriptions = data
        },
        setSubscriptionSummary(state, data) {
            state.subscriptionSummary = data
        },
        setCompanySubscriptionList(state, data) {
            state.companySubscriptionList = data
        },
    },
    actions: {
        async getSubscriptionList({commit}) {
            await axios.get(`/subscriptions/list`)
                .then(({data}) => {
                    commit("setSubscriptionList", data)
                })
        },
        async getSubscription({commit}, id) {
            await axios.get(`/subscriptions/get/${id}`)
                .then(({data}) => {
                    commit("setSubscription", data)
                })
        },
        async getSubscriptionSummary({commit}, id) {
            await axios.get(`/subscriptions/${id}/summary`)
                .then(({data}) => {
                    commit("setSubscriptionSummary", data)
                })
        },
        async setSubscription({commit}, data) {
            await axios.post(`/subscriptions/create`, data)
                .then(() => {
                    notify({
                        title: "Подписка сохранена",
                        type: "success"
                    })
                });
        },
        async updateSubscription({commit}, data) {
            await axios.put(`/subscriptions/update/${data.id}`, data).then(() => {
                notify({
                    title: "Подписка сохранена",
                    type: "success"
                })
            });
        },
        async getPermittedSubscriptions({commit}, id) {
            await axios.get(`/subscriptions/${id}/permitted`)
                .then(({data}) => {
                    commit("setPermittedSubscriptions", data)
                })
        },
        async getPermittedCompanies({commit}, id) {
            await axios.get(`/subscriptions/permitted_companies/${id}`)
                .then(({data}) => {
                    commit("setPermittedCompanies", data)
                })
        },
        async setSubscriptionAccess({commit}, data) {
            await axios.post(`/subscriptions/${data.subscription_id}/access`, data)
                .then(() => {
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    });
            })
        },
        async getCompanySubscriptionList({commit}) {
            await axios.get(`/subscriptions/company_subscriptions/all`)
                .then(({data}) => {
                    commit("setCompanySubscriptionList", data)
                })
        },
        async expireCompanySubscription({commit}, id) {
            await axios.post(`/subscriptions/expire/${id}`)
                .then(({data}) => {
                    notify({
                        title: "Подписка отключена",
                        type: "success"
                    });
                });
        },
    }
}