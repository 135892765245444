<template>
  <div class="registration-role-page">
    <div class="registration-role-page__message-block">
      <p>Выберите, как вы будете использовать сайт:</p>
      <div class="registration-role-page__role-btns flex flex-column">   
        <button @click="choice('private_person')" class="btn btn-accent">
          Физическое лицо
        </button>
        <p class="registration-role-page__description">
          - хочу развиваться самостоятельно
        </p>

        <button @click="choice('admin')" class="btn btn-accent">
          Юридическое лицо
        </button>
        <p class="registration-role-page__description">
          - хочу развивать эмоциональный интеллект сотрудников
        </p>

        <button @click="choice('admin')" class="btn btn-accent">
          Партнёр
        </button>
        <p class="registration-role-page__description">
          - хочу использовать платформу для ведения бизнеса
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import { ref } from '@vue/reactivity'
export default {
  name: "registration-role-page",
  setup(props, context) {
    const choice = (role) => {
      context.emit("roleChoice", role);
    };
    return { choice };
  },
};
</script>

<style lang="scss" scoped>
.registration-role-page {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__description {
    font-size: 17px;
    margin-bottom: 25px;
    font-weight: 300;
  }

  &__message-block {
    background-color: #fff;
    width: 50%;
    padding: 50px 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 7px;

    & > p {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 50px;
      text-align: center;
    }
  }

  &__role-btns {
    .btn:not(:last-child) {
      margin-bottom: 10px;
    }

    .btn {
      font-size: 18px;
      padding: 10px 16px;
    }
  }

  @media (max-width: 1024px) {
    &__message-block {
      width: 95%;
      padding: 50px 30px;
    }
  }
}
</style>