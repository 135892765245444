<template>
  <div class="redirect-page">
      <div class="redirect-page__message-block">
          <p class="redirect-page__message">Происходит переход на страницу авторизации.</p>
            <p class="redirect-page__message">Если страница не загрузилась, пожалуйста, нажмите на кнопку.</p>
            <div class="redirect-page__btn">
                <a class="btn btn-accent" :href="url">Перейти</a>
            </div>  
      </div>
  </div>
</template>

<script>
// import { ref } from '@vue/reactivity'
export default {
    name: "redirect-page",
    setup(){
        const url = `${process.env.VUE_APP_AUTH}/?site=${process.env.VUE_APP_FRONT}`;

        return {
            url
        }
    }

}
</script>

<style lang="scss" scoped>
.redirect-page{
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__message-block{
        background-color: #fff;
        width: 50%;
        padding: 100px;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 7px;
    }

    &__message{
        font-size: 20px;
        margin-bottom: 15px;
        text-align: center;
    }

    .btn{
        font-size: 18px;
        margin-top: 15px;
    }

    @media(max-width: 1024px){
        &__message-block{
            width: 95%;
        }
    }
}

</style>