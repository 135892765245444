import axios from "axios";
import {notify} from "@kyvg/vue3-notification";

export default {
    namespaced: true,

    state: () => ({
        taskList: [],
        colorList: [],
    }),

    mutations: {
        setTaskList(state, data) {
            state.taskList = data?.data;
        },
        setColorList(state, data) {
            state.colorList = data;
        },
    },

    actions: {
        async getTasks({ commit }) {
            await axios.get(`mood_tracker/tasks`).then(({ data }) => {
                commit("setTaskList", data);
            });
        },
        async getColors({ commit }) {
            await axios.get(`mood_tracker/colors`).then(({ data }) => {
                commit("setColorList", data);
            });
        },
        async createRecord({ commit }, data) {
            await axios.post(`mood_tracker/create`, data).then(() => {
                notify({
                    title: "Запись сохранена",
                    type: "success",
                });
            });
        },
    }
}
