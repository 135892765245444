<template>
  <div class="modal-backdrop">
    <div class="popup-content">
        <form @submit.prevent="updatePassword" class="password_form">
          <p>Необходимо указать новый пароль</p>
          <div>
            <custom-input
                v-model="form.password"
                name="password"
                :label="''"
                :inputWidth="200"
                :modelValue="1"
                autocomplete
                type="password"
                req
            />
            <div v-if="error.length" class="error_wrapper">
              <p>
                {{ error }}
              </p>
            </div>
          </div>
          <button type="submit" class="btn btn-accent mr-10">
            Сохранить
          </button>
        </form>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import {reactive} from "@vue/reactivity";
import {useStore} from "vuex";
import {ref} from "@vue/runtime-core";

export default {
  name: "PopUpChangePassword",
  components: {CustomInput},
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore();
    const error = ref('');

    const form = reactive({
      password: null,
      first_name: props.profile.first_name,
      last_name: props.profile.last_name,
      email: props.profile.email,
    });

    const updatePassword = () =>  {
      error.value = '';
      store.dispatch("users/updateUser", {
            id: props.profile.id,
            form: { ...form },
          })
          .then(() => (context.emit("close")))
          .catch(() => error.value = 'Пароль должке быть не короче 8 символов');
    }

    return {
      error,
      form,
      updatePassword,
    }
  }
}

</script>
<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  display: flex;
  padding: 10px;
  width: 40%;
  height: 300px;
  background-color: white;

  @media (max-width: 1024px) {
    width: 90%;
  }
}

.password_form {
  display: flex;
  margin: auto;
  align-items: center;
  flex-direction: column;
  row-gap: 15px;
}

.error_wrapper {
  margin-bottom: 10px;
  color: red;
  font-size: 14px;
  text-align: center;
  text-transform: lowercase;
}
</style>